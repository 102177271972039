.detail-title {
color: blue;
}

.productDetail {
  border-radius: 1%;
  width: 100%;
 
  .detail-title{
      color: rgb(183, 42, 56);
  padding: 0 !important;
  }
}
.productDetail .detail-title {
  color: black;
  padding: 0 !important;
}
.productDetail p {
  margin: 0 !important;
}
.product__details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 10px 0;
  width: 100%;
}
.product__details .detail-title {
  color: rgb(183, 42, 56);
  padding: 0 !important;
}

.prop {
  color: black;
}
.detail-images{
  margin-top: 3%;
  margin-left: 2%;
  padding: 5%;
.container{
  margin: 10px;
}
  .contain-small{
    display: flex;
    justify-self: center;
    align-items: center;
    margin: 15%;
    .small{
      img{
        max-height: 100px;
        border-radius: 50%;
        margin: -30%;
        box-shadow: 10px 10px 5px rgb(110, 105, 105);
      }
    }
    .small2{
      img{
        max-height: 100px;
        border-radius: 50%;
        border-radius: 50%;
        margin: -10%;
        transform: rotate(180deg);
        box-shadow: 10px 10px 5px rgb(110, 105, 105);
      }
    }
    .small3{
      img{
        max-height: 100px;
        border-radius: 50%;
        transform:rotate(90deg); 
       
      }
    }
      }
  .a{
    display: flex;
    img{
      max-width: 100%;
      max-height: 300px;
      min-width: 70%;
      object-fit: contain;
      
    }
  }
  
}
.active-image{
  max-height: 20%;
}

@media screen and (max-width: 576px) {
  .detail-images{
    margin-left: 15%;
    margin-right: 15%;
    .a{
      img{
        max-width: 100%;
        min-width: 70%;
        max-height: 200px;
      }
    }
    .small{
      img{
        max-width: 60%;
        min-width: 35%;
        max-height: 100px;
        object-fit: cover;
      }
    }
    .small2{
      img{
        max-width: 60%;
        min-width: 35%;
        max-height: 100px;
        object-fit: cover;
      }
    }
    .small3{
      img{
        max-width: 60%;
        min-width: 35%;
        max-height: 100px;
        object-fit: cover;
       
      }
    }
    
  }

}