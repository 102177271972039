$media-mobile: "only screen and (max-width : 600px)";
.navbar {
  position: sticky !important;
  // @include flex;
  // border:1px solid green;
  justify-content: space-between;
  // background:$color1;
  // padding: 1em;
  display: flex;
  align-items: center;
  top: 0 !important;
  a {
    text-decoration: none;
  }
  .logo {
    margin-left: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      border-radius: 50%;
      height: 3.7em;
    }
    .text {
      text-transform: uppercase;
      margin-left: 0.2em;
      color: $color7;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }
  .logo-mobile{
    margin-right: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      border-radius: 50%;
      height: 3.7em;
    }
    .text {
      text-transform: uppercase;
      margin-left: 0.2em;
      color: $color7;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }
  nav {
    @include flex;
    flex-wrap: nowrap !important;
 align-items: center;
 margin-right: 400px;
    // border:1px solid yellow;
    height: 100%;
    .nav-item {
      a {
        text-decoration: none;
        color: #666;
        &:hover {
          color: $color7;
        }
      }
      text-transform: uppercase;
      font-size: 0.88em;
      margin: 0 0.5em;
      color: #666;
      padding: 0 1em;
      letter-spacing: 1px;
      cursor: pointer;
      // border:1px solid red;
      &:hover {
        color: $color7;
      }
    }

    .dropbtn {
      color: #666;
    }

    /* The container <div> - needed to position the dropdown content */
    .dropdown {
      position: relative;
      display: inline-block;
    }

    /* Dropdown Content (Hidden by Default) */
    .dropdown-content {
      display: none;
      position: absolute;
      @include box;
      background-color: #fff;
      min-width: 160px;
      width: max-content;
      padding: 0.5em 0;
      z-index: 10;
      .nav-item {
        padding: 0.6em 1em;
        // border:1px solid red;
        a {
          // border:1px solid green;
          margin: 0;
          padding: 0;
        }
      }
    }

    /* Links inside the dropdown */
    .dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }

    /* Change color of dropdown links on hover */
    .dropdown-content a:hover {
      // background-color: #ddd;
    }

    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {
      display: block;
    }

    /* Change the background color of the dropdown button when the dropdown content is shown */
    // .dropdown:hover .dropbtn {background-color: #3e8e41;}

    .more-categories {
      cursor: pointer;
      margin: 0 0.5em;
      color: #666;
      &:hover {
        color: $color1;
      }
    }
    .search-box {
      .search-bar {
        // width: 500px;
        // flex-grow: 2;
        //  border:1px solid red;
        background: $color7;
        // background: #ffd699;
        display: flex;
        align-items: center;
        border-radius: 25px;
        .input-outer {
          // border:1px solid red;
          width: 100%;
          height: 100%;
          // background: $color7;
          background-color: white;
          // background: #ffeed4;
          border-radius: 25px;
          align-items: center;
          border: 1px solid $color2;
          .input {
            // border:1px solid green;
            width: 100%;
            height: 100%;
            margin: 0;
            border-radius: 25px;
            // background: $color8;
            // background-color: white;
            // background: #ffeed4;
            border: none;
            outline: none;
            padding: 0.5em 1em;
            // color: white;
          }
          .clear {
            padding: 0 0.7em;
            opacity: 0.8;
            font-weight: bold;
            cursor: pointer;
            font-size: 1.4em;
            &:hover {
              opacity: 1;
            }
          }
        }

        .icon {
          cursor: pointer;
          // border:1px solid red;
          font-size: 1.2em;
          margin: 0 0.5em;
          padding: 0.2em;
          color: white;
          // color: #ff9900;
        }
      }
      &:focus {
        margin: 10em;
      }
     .search__result__holder {
       margin-top: 5px;
  background-color: white;
  width: 30%;
  border-radius: 0 0 15px 15px;
  box-shadow: rgba(149, 157, 165, 0.8) 0px 8px 24px;
  z-index: 99;
  max-height: 70vh;
  padding: 0 15px;
  left: 35%;
  position: absolute;
  top: 100%;
  overflow-y: auto !important;
  scrollbar-width: 10px;
  @media #{$media-mobile} {
    width: 96%;
    left: 2%;
  }
  
.no__record {
  padding: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: rgb(20, 61, 89);
  text-transform: capitalize;
  :last-child {
    font-size: 40px;
    color: rgba(244, 151, 3, 0.7);
  }
  @media #{$media-mobile} {
    padding: 15px;
    font-size: 15px;
  }
}
  .search__results {
    cursor: pointer;
    padding: 10px;
    margin: 10px 0px;
    width: 100%;
    background-color: white;
    @media #{$media-mobile} {
      padding: 10px;
    }
    border-bottom: 1px solid rgba(60, 64, 67, 0.3);
    text-decoration: none !important;
    color: rgb(20, 61, 89);
    .link{
      color:$color7;
    }
  }
}
    }
    .navbar-categories {
      margin: 0 1em;
      position: relative;
      .categories-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: color 0.2s ease-in-out;
        .name {
          margin: 0 0.3em;
          font-size: 1.2em;
        }
        .icon {
          font-size: 1.2em;
        }
        &:hover {
          color: $color7;
        }
      }
      .categories-list {
        animation: 0.25s ease-out 0s 1 slideDown;
        position: absolute;
        z-index: 1000;
        width: max-content;
        // border:1px solid red;
        background: white;
        // padding:.51em;
        margin-top: 0.6em;
        border-radius: 10px;
        box-shadow: 0 0 10px #ccc;
        .category-item {
          // border:1px solid red;
          padding: 0.7em;
          cursor: pointer;
          &:hover {
            background-color: rgb(245, 245, 245);
          }
        }
      }
    }
  }
  .icons {
    @include flex;
    .icon {
      position: relative;
      color: $color7;
      margin: 0.1em;
      padding: 0.4em;
      cursor: pointer;
      transition: transform 0.13s ease-in-out;
      .badge {
        position: absolute;
        top: 0;
        right: 0;
        // border: 1px solid black;
        padding: 0;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: $color8;
        color: white;
        font-size: 0.65em;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:hover {
        color: $color8;
        transform: scale(1.2);
      }
    }
  }
  .toggle-button {
    cursor: pointer;
    margin-left: 30px;
  }
}
.navbar-stick {
  animation: 0.25s ease-out 0s 1 slideInFromTop;
  position: fixed !important;
  top: 0;
  left: 0;
  max-height: fit-content;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
  background: white;
  width: 100%;
  right: 0;

  bottom: 0;
  transition: transform 200ms ease-in-out;
  // background-color: aquamarine;
}
@media screen and (max-width: 1054px) {
  .navbar{
    nav{
margin-right: 200px;

    }
    .logo{
      margin-left: 10px;
    }
  }
}
.drawer_items{
  padding: 0.75em;
  color: #666;
  cursor: pointer;
}