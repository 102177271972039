$breakpoint-tablet: 768px;
.info-page {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6em;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: $color7;
    padding: 2em 0;
    color: white;
    // background-image: url("../images/adimera-footer.png");
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
  }
  .content {
    padding: 1em;
  }
}
.about-page {
  // border:1px solid red;
  .about-top {
    font-size: 1.5em;
    width: 70%;
    padding: 2em 0;
    text-align: center;
    margin: auto;
  }
  .about-tabs {
    width: 80%;
    margin: auto;
    // border:1px solid red;
    display: flex;
    justify-content: space-between;
    .item {
      box-sizing: border-box;
      text-transform: uppercase;
      font-size: 1.2em;
      padding: 0.5em 0;
      font-weight: bold;
      cursor: pointer;
    }
    .item-selected {
      border-bottom: 3px solid $color7;
    }
  }
  .about-tabs-mobile {
    flex-direction: column;
  }

  .about-bottom {
    width: 90%;
    margin: 1em auto;
    align-items: center;
    display: flex;
    .icon {
      flex: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 8rem;
      color: $color7;
      @media (max-width: $breakpoint-tablet) {
        align-self: flex-start;
        font-size: 4rem;
      }
    }
    .text {
      flex: 5;
      padding: 2em;
      margin-bottom: 1em;
      font-size: 1.5em;
      font-weight: bold;
      text-align: center;
      .we-got-you {
        color: $color7;
      }
    }
  }
}
.about-content {
  // border:1px solid green;
  width: 90%;
  margin: auto !important;
  padding: 2em 0;
  font-size: 1.1em;
  .about-content-item {
    min-height: 300px;
    // width:90%;
    // margin:auto;
    border: 1px solid $color7;
    border-radius: 10px;
    padding: 1em;
    opacity: 0;

    opacity: 1;
    .content-header {
      // border :1px solid red;
      font-weight: bold;
      font-size: 1.13em;
      color: $color7;
    }
    .text {
      padding: 2em 0;
    }
  }
}
.contact-us-page {
  .contact-us-item {
    min-height: 100px;
    // width:90%;
    // margin:auto;
    border: 1px solid $color7;
    border-radius: 10px;
    padding: 1em;
    opacity: 0;
    white-space: pre-line;
    opacity: 1;
    .content-header {
      // border :1px solid red;
      font-weight: bold;
      font-size: 1.13em;
      color: $color7;
    }
    .contact-info {
      margin: 0.4em 0;
    }
    // .text {
    //   padding: 2em 0;
    // }
    .social-icons {
      // border:1px solid red;

      .icon-item {
        // border:1px solid red;
        @include flex;
        padding: 0.5em 0;
        // align-items: center;
        height: max-content;
        cursor: pointer;

        .icon {
          // border:1px solid red;
          margin-right: 0.3em;
        }
        &:hover {
          color: #ddd;
          // color: $color1;
        }
      }
    }
  }
}
.print-download {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  margin: auto;
  .adimera-btn {
    margin: 0 1em;
    width: 150px;
    text-align: center;
  }
}
