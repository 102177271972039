  .positionHeader {
animation: 0.25s ease-out 0s 1 slideInFromTop;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
  width: 100%;
 transition: transform 200ms ease-in-out;
}
  .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  