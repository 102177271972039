.messages {
    padding:1em;
}
.message-item{
    padding:1em;
    margin:1em;
    border-radius: 18px;;
    width:60%;
    // background-color: red;
}
.message-item-user{
   
    background-color: rgb(213, 247, 213);
}
.message-item-seller{
   
    background-color: rgb(231, 231, 231);
}