$color1: #e6b800;
$color2: #9c6615;
$color3: #b59677;
$color4: #a68059;
$color5: #a88766;
$color6: rgb(255, 175, 51);
$color7: #141414;
// $color7: #ffaf33;
$color8: #e6e7e9;
// $color8: #e69a29;
$color9: #eebdbf;
$color10: #b28671;
